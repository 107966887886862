import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Drawer, Form, Popover, Icon, Button, Switch, Alert, Tooltip } from 'antd';
import BaseTemplateForm from '../BaseTemplateForm';
import './CreateTextTemplate.scss';
import RichTextEditor from '../../../Editor/RichTextEditor';
import { validateTemplate } from '../../../../Utils/Validators';
import messages from './messages';
import ExclamationCircleIcon from '../../../../Icons/ExclamationCircleIcon';
import eventTypes from '../../../../Analytics/EventTypes';
import InfoCircleIcon from '../../../../Icons/SuggestedIcons/InfoCircleIcon';
import { MergeTagIcon } from '../../../../Icons/AryaIcons';
import candidateDrawerMessage from '../../../../Containers/CandidateDrawer/messages';
import { checkForJunkWordsInText, messageCount } from '../../../Utils/SmsUtils';
import SpamAlertText from '../../../Common/SpamTextAlert/SpamTextAlert';

function createRequestBody({ values, textBody, isConsent, initialValues }) {
  return {
    Name: values['template-name'],
    Body: textBody,
    IsConsent: isConsent,
    IsPublic: initialValues?.IsPublic ?? false,
  };
}

const debouncedCheckForJunkWordsInText = _.debounce((plainTextContent, setter, junkWordsSetter) => {
  const { hasJunkWords, junkWords } = checkForJunkWordsInText(plainTextContent);
  setter(hasJunkWords);
  junkWordsSetter(junkWords);
}, 600);

class CreateTextTemplate extends BaseTemplateForm {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.addTags = this.addTags.bind(this);
    this.state = { initalTextBody: undefined, isJunkWordFound: false, junkWords: [] };
    this.saveTemplate = this.saveTemplate.bind(this);
    this.testTemplate = this.testTemplate.bind(this);
    this.addTags = this.addTags.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickConsentSwitch = this.onClickConsentSwitch.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = _.cloneDeep(state);
    if (props.initialValues.Body !== state.initalTextBody) {
      newState.textBody = props.initialValues.Body;
      newState.initalTextBody = props.initialValues.Body;
      newState.isConsent = props.initialValues.IsConsent;
    }
    return newState;
  }

  addTags(tag) {
    this.myRef.current.addTags(tag, false);
  }

  validateTextTemplate = ({ textBody }) => {
    const { mergeTags } = this.props;
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    return validateTemplate({ body: textBody, bodyMergeTags: mergeTagsForMessage });
  };

  saveTemplate(event) {
    const { form, onSubmit, initialValues, setInvalidTemplateNotification } = this.props;
    const { isConsent } = this.state;
    event.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const textBody = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
        if (!this.validateTextTemplate({ textBody })) {
          setInvalidTemplateNotification();
          return;
        }
        onSubmit(createRequestBody({ values, textBody, isConsent, initialValues }));
        this.setState({ textBody: '' });
        form.resetFields();
      }
    });
  }

  onClickConsentSwitch(value) {
    this.setState({ isConsent: value });
  }

  onChange({ htmlContent, plainTextContent }) {
    this.setState({ textBody: htmlContent, body: plainTextContent }, () => {
      debouncedCheckForJunkWordsInText(plainTextContent, this.setIsJunkWordFound, this.setJunkWordsFound);
    });
  }

  testTemplate() {
    const { testTextTemplate, setInvalidTemplateNotification } = this.props;
    const { isConsent } = this.state;
    const textBody = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n');
    if (!this.validateTextTemplate({ textBody })) {
      setInvalidTemplateNotification();
      return;
    }
    testTextTemplate(textBody, isConsent);
  }

  setIsJunkWordFound = value => {
    this.setState({ isJunkWordFound: value });
  };

  setJunkWordsFound = value => {
    this.setState({ junkWords: value });
  };

  render() {
    const {
      title,
      onClose,
      visible,
      initialValues,
      mergeTags,
      testTemplateStatus,
      viewOnly,
      featureToggleList,
      intl,
      consentText,
    } = this.props;
    let { textBody } = this.state;
    const { isConsent, isJunkWordFound, junkWords } = this.state;
    textBody = (textBody || '').replace(/\n/g, '<br />');
    const mergeTagsForMessage = mergeTags.filter(mergeTag => mergeTag.Scopes.includes('Sms'));
    const {
      MessageConsentTemplateCreation: { IsEnabled: messageConsentTemplateCreationEnabled },
    } = featureToggleList;

    const isConsentCreationSwitchHidden =
      Object.keys(initialValues).length === 0 && !messageConsentTemplateCreationEnabled;

    const isConsentCreationDisabled = viewOnly || !messageConsentTemplateCreationEnabled;

    let isDisable = true;
    if (this.myRef.current) {
      isDisable = _.trimEnd(this.myRef.current.quillRef.getText().trim(), '\n').length === 0;
    }
    const {
      props: {
        values: { totalTextCount },
      },
    } = messageCount(textBody, true);
    if (totalTextCount > 7) isDisable = true;

    const isTestBtnEnabled = (!this.myRef.current && initialValues.Body) || !isDisable;
    let buttonText = <FormattedMessage {...candidateDrawerMessage.testTextLabel} />;
    if (testTemplateStatus === 'INPROGRESS') {
      buttonText = 'Testing';
    } else if (testTemplateStatus === 'COMPLETED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="success-icon" type="check-circle" />
          </div>
          <div className="test-email-text">
            {' '}
            <FormattedMessage {...candidateDrawerMessage.testTextLabel} />{' '}
          </div>
        </div>
      );
    } else if (testTemplateStatus === 'FAILED') {
      buttonText = (
        <div className="test-email-wrapper ">
          <div>
            <Icon className="failed-icon" type="close-circle" theme="filled" />
          </div>
          <div className="test-email-text">
            <FormattedMessage {...candidateDrawerMessage.testTextLabel} />
          </div>
        </div>
      );
    }

    const popoverContent = (
      <div className="tags-popover-content">
        {mergeTagsForMessage.map(mergeTag => (
          <div
            className="merge-tag"
            onClick={() => {
              this.addTags(mergeTag);
            }}
            role="presentation"
          >
            +{' '}
            {mergeTag.DisplayName === 'Chatbot URL' || mergeTag.DisplayName === 'AryaBot URL'
              ? 'LeoBot URL'
              : mergeTag.DisplayName}
          </div>
        ))}
      </div>
    );

    const addMergeTags = (
      <Popover content={popoverContent} placement="left" trigger="click">
        <Tooltip zIndex={2223} title={intl.formatMessage({ ...candidateDrawerMessage.mergeTagsLabel })}>
          <span>
            <MergeTagIcon />
          </span>
        </Tooltip>
      </Popover>
    );
    return (
      <Drawer
        className="create-template text drawer"
        destroyOnClose
        title={title}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={650}
      >
        <Form onSubmit={this.saveTemplate}>
          <div className="row-one">
            {this.getTemplateNameItem(
              intl.formatMessage({ ...candidateDrawerMessage.textTemplateNameLabel }),
              intl.formatMessage({ ...candidateDrawerMessage.pleaseInputTheTemplateName }),
              initialValues.Name
            )}

            {!viewOnly ? (
              <Tooltip
                title={
                  <div>
                    <FormattedMessage {...candidateDrawerMessage.selectTextTextInfo} />
                    <br />
                    <FormattedMessage {...candidateDrawerMessage.testTextWillBeSent} />
                    <br />
                    <FormattedMessage {...candidateDrawerMessage.inYourProfile} />
                  </div>
                }
                placement="topRight"
              >
                <span>
                  <Button
                    shape="round"
                    className="create-template test"
                    onClick={this.testTemplate}
                    loading={testTemplateStatus === 'INPROGRESS'}
                    disabled={!isTestBtnEnabled}
                  >
                    {buttonText}
                  </Button>
                </span>
              </Tooltip>
            ) : null}
          </div>
          {!isConsentCreationSwitchHidden ? (
            <div className="create-text-tempate-consent-wrapper">
              <span className="create-template-consent-template">
                {' '}
                <FormattedMessage {...candidateDrawerMessage.consentTemplateLabel} />
              </span>
              <span className="switchAndInfoIcon">
                <Switch
                  checkedChildren={<FormattedMessage {...candidateDrawerMessage.onLabel} />}
                  onClick={this.onClickConsentSwitch}
                  checked={isConsent}
                  disabled={isConsentCreationDisabled}
                />
                <Tooltip
                  title={
                    <div>
                      <FormattedMessage {...candidateDrawerMessage.engagementConsentTextInfoLabel} />
                      <br />
                      <FormattedMessage {...candidateDrawerMessage.toggleThisButton} />
                      <br />
                      <FormattedMessage {...candidateDrawerMessage.puropseOfCollectingLabel} />
                    </div>
                  }
                  placement="topLeft"
                  overlayClassName="consentInfoTooltip"
                >
                  <InfoCircleIcon />
                </Tooltip>
              </span>
            </div>
          ) : null}
          {!viewOnly ? addMergeTags : null}
          <div className="create-template-editor">
            <RichTextEditor
              editorContent={{ htmlContent: textBody }}
              onChange={this.onChange}
              mergeTags={mergeTags}
              ref={this.myRef}
              showToolbar={false}
              disabled={viewOnly}
              editorContext="Message"
            />
            <div className="message-count-container">
              {messageCount(textBody, true)}
              <div className="message-count-info-container">
                <Tooltip
                  zIndex={2223}
                  title={<FormattedMessage {...candidateDrawerMessage.totalCharactersLabel} />}
                  placement="rightBottom"
                >
                  <ExclamationCircleIcon />
                </Tooltip>
              </div>
            </div>
            {isConsent ? (
              <div>
                <Alert
                  message={<FormattedMessage {...candidateDrawerMessage.textConsentFooter} />}
                  type="info"
                  showIcon
                />
                <div className="create-template-consent-text">
                  {consentText || <FormattedMessage {...messages.textConsentMessage} />}
                </div>
              </div>
            ) : null}
            {isJunkWordFound ? <SpamAlertText junkWords={junkWords} /> : null}
          </div>
          <br />
          <div className="footer">
            <Tooltip
              getPopupContainer={node => node.parentNode}
              title={
                isDisable ? (
                  <FormattedMessage {...candidateDrawerMessage.sendButtonDisabledMaxTextCountTooltipText} />
                ) : null
              }
            >
              <div>
                {!viewOnly
                  ? this.getSaveButton(
                      <FormattedMessage {...candidateDrawerMessage.saveTemplateLabel} />,
                      isDisable,
                      eventTypes.connect.smsTemplates.save
                    )
                  : null}
              </div>
            </Tooltip>
            {!viewOnly
              ? this.getCancelButton(
                  <FormattedMessage {...candidateDrawerMessage.cancelButton} />,
                  onClose,
                  eventTypes.connect.smsTemplates.cancel
                )
              : null}
          </div>
        </Form>
      </Drawer>
    );
  }
}

CreateTextTemplate.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  textTemplateName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
};

CreateTextTemplate.defaultProps = {
  initialValues: {},
};

export default injectIntl(Form.create()(CreateTextTemplate));
export { CreateTextTemplate as CreateTextTemplateWithoutForm };
