const scoreScale = [
  {
    FromValue: 1.0,
    ToValue: 10.0,
    Score: 10,
  },
  {
    FromValue: 10.0,
    ToValue: 20.0,
    Score: 20,
  },
  {
    FromValue: 20.0,
    ToValue: 30.0,
    Score: 30,
  },
  {
    FromValue: 30.0,
    ToValue: 40.0,
    Score: 40,
  },
  {
    FromValue: 40.0,
    ToValue: 50.0,
    Score: 50,
  },
  {
    FromValue: 50.0,
    ToValue: 60.0,
    Score: 60,
  },
  {
    FromValue: 60.0,
    ToValue: 70.0,
    Score: 70,
  },
  {
    FromValue: 70.0,
    ToValue: 80.0,
    Score: 80,
  },
  {
    FromValue: 80.0,
    ToValue: 90.0,
    Score: 90,
  },
  {
    FromValue: 90.0,
    ToValue: 100.0,
    Score: 100,
  },
];
const properties = [
  'Operating Locations',
  'Annual Revenue',
  'Arya Popularity',
  'Leoforce Popularity',
  'Employee Tenure',
  'Company Size',
];

export const scale = Object.fromEntries(properties.map(property => [property, scoreScale]));
